import * as React from 'react';
import PropTypes from 'prop-types';
import { Set } from 'immutable';
import { injectIntl } from 'react-intl';
import { Icon, Grid, Popover, Button, Typography } from '@upperhand/playmaker';

import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';

import UserAvatar from 'shared/components/_UserAvatar.jsx';

import { t } from 'shared/utils/LocaleUtils.js';
import { formattedHourString } from 'calendar/utils/CalendarUtils.jsx';
import { compose } from 'shared/utils/SharedUtils.js';
import altContainer from 'shared/hocs/altContainer.jsx';

import Session from 'shared/records/Session';
import EventTime from 'calendar/records/EventTime.jsx';
import CustomerEvent from 'event_mgmt/shared/records/CustomerEvent.jsx';

import CalendarActions from 'calendar/actions/CalendarActions.jsx';

import { SessionDataStore } from 'dataStores';

import './styles.scss';

class EventTimeNote extends React.PureComponent {
  state = {
    anchorEl: null,
  };

  handleNoteClicked = e => {
    const { onClickViewNotes = () => null } = this.props;

    this.setState({ anchorEl: e.currentTarget });
    e.preventDefault();
    onClickViewNotes(e);
  };

  handleNoteClose = e => {
    const { onCloseNotes = () => null } = this.props;

    this.setState({ anchorEl: null });
    onCloseNotes(e);
  };

  handleAddOrEditNote = e => {
    const { eventTime } = this.props;
    const { event_id: eventId, session_id: sessionId } = eventTime;

    CalendarActions.viewSessionClicked({
      eventId,
      sessionId,
      selectedTab: 2,
    });
    this.handleNoteClose(e);
  };

  render() {
    const {
      clients = Set(),
      labelText = '',
      eventTime = new EventTime(),
      event = new CustomerEvent(),
      sessionDataStore = {},
      readOnly,
      intl,
    } = this.props;
    const { sessions } = sessionDataStore;
    const sessionId = eventTime.get('session_id');
    const session = sessions.get(sessionId, new Session());
    const staffNote = session.get('note') || eventTime.get('note') || '';
    const clientNote =
      session.get('client_note') || eventTime.get('client_note');
    const clientsWithNotes = clients.filter(client =>
      client.getHtmlNoteCleaned()
    );

    if (!staffNote && !clientNote && clientsWithNotes.size === 0) return null;

    const dateRange = eventTime.dateRange();
    const startTime = formattedHourString(dateRange.start);
    const endTime = formattedHourString(dateRange.end);
    const eventType = event.get('event_type');
    const eventTypeName = eventType.get('name');
    const { anchorEl } = this.state;

    return (
      <div className="session-notes">
        <a href="/" className="view-note-btn" onClick={this.handleNoteClicked}>
          <InsertDriveFileIcon sx={{ color: '#FFBC07' }} />
          {labelText !== '' && <p>{labelText}</p>}
        </a>
        <Popover
          classes={{
            root: 'notes-popover',
            content: 'notes-popover-content',
          }}
          anchor={anchorEl}
          onClose={this.handleNoteClose}
        >
          <div className="notes-popover-content__wrapper">
            <div className="notes-popover-content__header">
              <div className="event-info">
                <p className="event-time">{`${startTime} - ${endTime}`}</p>
                <p className="event-type">{eventTypeName}</p>
              </div>
              <div
                className={
                  readOnly ? 'notes-actions-view-only' : 'notes-actions'
                }
              >
                {!readOnly && (
                  <Button
                    rounded
                    icon={staffNote ? 'edit' : 'add'}
                    type="tertiary"
                    onClick={this.handleAddOrEditNote}
                  />
                )}

                <Button
                  rounded
                  icon="close"
                  type="tertiary"
                  onClick={this.handleNoteClose}
                />
              </div>
            </div>
            <Grid
              container
              alignItems="center"
              className="notes-popover-content__info"
            >
              {staffNote && (
                <>
                  <Grid
                    item
                    container
                    alignItems="center"
                    xs={12}
                    className="staff-note__container"
                  >
                    <Grid item>
                      <Icon name="staff" size="large" />
                    </Grid>
                    <Grid item>
                      <div style={{ paddingLeft: 5 }}>
                        {t('.staff_note_label', intl, __filenamespace)}
                      </div>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography>{staffNote}</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <hr className="divider" />
                  </Grid>
                </>
              )}
              {clientNote && (
                <>
                  <Grid
                    item
                    container
                    alignItems="center"
                    xs={12}
                    className="staff-note__container"
                  >
                    <Grid item>
                      <Icon name="staff" size="large" />
                    </Grid>
                    <Grid item>
                      <div style={{ paddingLeft: 5 }}>
                        {t('.client_note_label', intl, __filenamespace)}
                      </div>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography>{clientNote.description}</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <hr className="divider" />
                  </Grid>
                </>
              )}
              {clientsWithNotes.map(client => (
                <Grid
                  item
                  container
                  xs={12}
                  className="client-note-item"
                  key={client.id}
                >
                  <Grid item container alignItems="center">
                    <Grid item>
                      <UserAvatar user={client} size={20} />
                    </Grid>
                    <Grid item>
                      <div className="attendee-info">
                        <span className="client-name">{client.name()}</span>
                        <span className="client-type">
                          &nbsp; &#8226; &nbsp;
                        </span>
                        <span className="client-type">
                          {t('.client_note', intl, __filenamespace)}
                        </span>
                      </div>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <div
                      className="rich-text-container"
                      // eslint-disable-next-line react/no-danger
                      dangerouslySetInnerHTML={{
                        __html: client.getHtmlNote(),
                      }}
                    />
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </div>
        </Popover>
      </div>
    );
  }
}

EventTimeNote.propTypes = {
  onClickViewNotes: PropTypes.func,
  onCloseNotes: PropTypes.func,
  clients: PropTypes.instanceOf(Set),
  labelText: PropTypes.string,
  eventTime: PropTypes.instanceOf(EventTime),
  event: PropTypes.instanceOf(CustomerEvent),
};

export default compose(
  injectIntl,
  altContainer({
    stores: {
      sessionDataStore: SessionDataStore,
    },
  })
)(EventTimeNote);
