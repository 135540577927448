import * as React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';

import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Switch from '@mui/material/Switch';

import CustomerActions from 'shared/actions/CustomerActions.jsx';
import ExplanatoryText from 'shared/components/_ExplanatoryText.jsx';
import StateChangingButton from 'shared/components/_StateChangingButton.jsx';
import Expander from 'shared/components/Expander.jsx';
import { FlexBox } from 'shared/components/FlexBox.jsx';

import { LEFT_MARGIN_PIXELS, SINGLE_COLUMN_WIDTH } from 'shared/utils/DOMUtils';
import { handleChange } from 'shared/helpers/ChangeHandler.jsx';
import { merge } from 'shared/utils/ObjectUtils.jsx';
import { t, messageId } from 'shared/utils/LocaleUtils.js';
import { uhColors } from 'shared/styles/uhStyles.jsx';
import { enabledCustomerFeatures } from 'shared/utils/CustomerUtils';

const onChange = (e, value) => {
  const obj = {};
  obj[e.target.name] = value;
  handleChange({ actions: CustomerActions })(null, obj, 'preferences');
};

const styles = {
  root: {
    flexWrap: 'wrap',
    fontSize: 15,
    marginLeft: LEFT_MARGIN_PIXELS,
    marginRight: LEFT_MARGIN_PIXELS,
    marginBottom: LEFT_MARGIN_PIXELS,
    maxWidth: '90%',
  },

  column: {
    flex: `0 1 ${SINGLE_COLUMN_WIDTH}`,
    marginTop: LEFT_MARGIN_PIXELS,
    maxWidth: '100%',
  },

  preference: {
    marginBottom: 30,
  },

  preferenceHeader: {
    fontWeight: 'bold',
    marginBottom: 20,
  },
  preferenceAccordion: {
    backgroundColor: '#34353612',
    marginBottom: '4px',
    padding: '0 8px',
    color: 'black',
  },
  preferenceColor: {
    color: '#7f7f7f',
  },
  preferenceAccordionDetailDiv: {
    display: 'flex',
    margin: '0 38px',
    justifyContent: 'center',
    padding: '0.8rem .4rem',
  },
  schedulingToggle: {
    flexDirection: 'row-reverse',
    justifyContent: 'space-between',
    margin: 0,
  },
};

function Preference({ name, children, style }) {
  return (
    <div style={merge(styles.preference, style)}>
      <h2 style={styles.preferenceHeader}>{name}</h2>
      {children}
    </div>
  );
}

function DisableClientSchedulingToggle({ preferences, intl, style }) {
  return (
    <Preference
      name={t('.scheduling_header', intl, __filenamespace)}
      style={style}
    >
      <FormGroup>
        <FormControlLabel
          label={t('.disable_client_scheduling_label', intl, __filenamespace)}
          control={
            <Switch
              name="disable_client_scheduling"
              checked={preferences.get('disable_client_scheduling', false)}
              onChange={onChange}
            />
          }
          style={styles.schedulingToggle}
        />
      </FormGroup>
      <ExplanatoryText
        style={{ marginTop: '1em' }}
        text={t('.disable_client_scheduling_help', intl, __filenamespace)}
      />
    </Preference>
  );
}

function DisableRSVPToggle({ preferences, intl, style }) {
  return (
    <Preference name={t('.rsvp_header', intl, __filenamespace)} style={style}>
      <FormGroup>
        <FormControlLabel
          label={t('.disable_rsvp_label', intl, __filenamespace)}
          control={
            <Switch
              name="enabled_rsvp_section"
              checked={preferences.get('enabled_rsvp_section', false)}
              onChange={onChange}
            />
          }
          style={styles.schedulingToggle}
        />
      </FormGroup>
      <ExplanatoryText
        style={{ marginTop: '1em' }}
        text={t('.disable_rsvp_help', intl, __filenamespace)}
      />
    </Preference>
  );
}

function ClientPaymentMethodAccordion({ intl, preferences }) {
  const isACHEnabled = enabledCustomerFeatures(['ach']);

  const onChangeCheckBoxEvent = (e, type, name) => {
    const updatePreference = preferences.get(`${type}`);
    updatePreference[`${name}`] = e.target.checked;
    const newPreference = preferences.set(`${type}`, updatePreference);
    handleChange({ actions: CustomerActions })(
      null,
      newPreference,
      'preferences'
    );
  };

  return (
    <>
      <Expander
        label={t('.event', intl, __filenamespace)}
        style={styles.preferenceAccordion}
        labelContainerStyle={styles.preferenceColor}
      >
        <div style={styles.preferenceAccordionDetailDiv}>
          <FormGroup>
            <FormControlLabel
              label={t('.card_checkbox_label', intl, __filenamespace)}
              control={
                <Checkbox
                  checked={preferences?.get('event_checkout')?.card}
                  name="card"
                  onChange={e =>
                    onChangeCheckBoxEvent(e, 'event_checkout', 'card')
                  }
                />
              }
            />
          </FormGroup>
          <FormGroup>
            <FormControlLabel
              label={t('.payLater_checkbox_label', intl, __filenamespace)}
              control={
                <Checkbox
                  checked={preferences?.get('event_checkout')?.pay_later}
                  name="pay_later"
                  onChange={e =>
                    onChangeCheckBoxEvent(e, 'event_checkout', 'pay_later')
                  }
                />
              }
            />
          </FormGroup>
          {isACHEnabled && (
            <FormGroup>
              <FormControlLabel
                label={t('.ach_checkbox_label', intl, __filenamespace)}
                control={
                  <Checkbox
                    checked={preferences?.get('event_checkout')?.bank}
                    name="pay_later"
                    onChange={e =>
                      onChangeCheckBoxEvent(e, 'event_checkout', 'bank')
                    }
                  />
                }
              />
            </FormGroup>
          )}
        </div>
      </Expander>
      <Expander
        label={t('.memberships', intl, __filenamespace)}
        style={styles.preferenceAccordion}
        labelContainerStyle={styles.preferenceColor}
      >
        <div style={styles.preferenceAccordionDetailDiv}>
          <FormGroup>
            <FormControlLabel
              label={t('.card_checkbox_label', intl, __filenamespace)}
              control={
                <Checkbox
                  checked={preferences?.get('membership_checkout')?.card}
                  name="card"
                  onChange={e =>
                    onChangeCheckBoxEvent(e, 'membership_checkout', 'card')
                  }
                />
              }
            />
          </FormGroup>
          <FormGroup>
            <FormControlLabel
              label={t('.payLater_checkbox_label', intl, __filenamespace)}
              control={
                <Checkbox
                  checked={preferences?.get('membership_checkout')?.pay_later}
                  name="pay_later"
                  onChange={e =>
                    onChangeCheckBoxEvent(e, 'membership_checkout', 'pay_later')
                  }
                />
              }
            />
          </FormGroup>
          {isACHEnabled && (
            <FormGroup>
              <FormControlLabel
                label={t('.ach_checkbox_label', intl, __filenamespace)}
                control={
                  <Checkbox
                    checked={preferences?.get('membership_checkout')?.bank}
                    name="pay_later"
                    onChange={e =>
                      onChangeCheckBoxEvent(e, 'membership_checkout', 'bank')
                    }
                  />
                }
              />
            </FormGroup>
          )}
        </div>
      </Expander>
      <Expander
        label={t('.creditPasses', intl, __filenamespace)}
        style={styles.preferenceAccordion}
        labelContainerStyle={styles.preferenceColor}
      >
        <div style={styles.preferenceAccordionDetailDiv}>
          <FormGroup>
            <FormControlLabel
              label={t('.card_checkbox_label', intl, __filenamespace)}
              control={
                <Checkbox
                  checked={preferences?.get('credit_pass_checkout')?.card}
                  name="card"
                  onChange={e =>
                    onChangeCheckBoxEvent(e, 'credit_pass_checkout', 'card')
                  }
                />
              }
            />
          </FormGroup>
          <FormGroup>
            <FormControlLabel
              label={t('.payLater_checkbox_label', intl, __filenamespace)}
              control={
                <Checkbox
                  checked={preferences?.get('credit_pass_checkout')?.pay_later}
                  name="pay_later"
                  onChange={e =>
                    onChangeCheckBoxEvent(
                      e,
                      'credit_pass_checkout',
                      'pay_later'
                    )
                  }
                />
              }
            />
          </FormGroup>
          {isACHEnabled && (
            <FormGroup>
              <FormControlLabel
                label={t('.ach_checkbox_label', intl, __filenamespace)}
                control={
                  <Checkbox
                    checked={preferences?.get('credit_pass_checkout')?.bank}
                    name="pay_later"
                    onChange={e =>
                      onChangeCheckBoxEvent(e, 'credit_pass_checkout', 'bank')
                    }
                  />
                }
              />
            </FormGroup>
          )}
        </div>
      </Expander>
    </>
  );
}

function ClientPaymentMethodCheckboxes({ preferences, errors, intl, style }) {
  return (
    <Preference
      name={t('.payment_method_header', intl, __filenamespace)}
      style={style}
    >
      <ExplanatoryText
        text={t('.payment_method_help', intl, __filenamespace)}
      />
      <ExplanatoryText
        style={{ color: uhColors.errorColor }}
        text={errors.getErrors(['preferences', 'client_payment_methods'], intl)}
      />
      <ClientPaymentMethodAccordion preferences={preferences} intl={intl} />
    </Preference>
  );
}

function ClientEmailSettings({ emailPreferences, intl }) {
  const allActive = !emailPreferences.some(p => !p);

  return (
    <Preference name={t('.email_settings', intl, __filenamespace)}>
      <ExplanatoryText
        text={t('.email_settings_help', intl, __filenamespace)}
      />
      <FormGroup style={{ marginTop: '1em' }}>
        <FormControlLabel
          label={t('.all_emails', intl, __filenamespace)}
          control={
            <Checkbox
              checked={allActive}
              name="all"
              onChange={(_e, checked) => {
                CustomerActions.changeEmailPreference({
                  preference: 'all',
                  checked,
                });
              }}
            />
          }
        />
        {emailPreferences.entrySeq().map(([preference, value]) => (
          <FormControlLabel
            key={preference}
            label={
              <FormattedMessage
                id={messageId(`.${preference}`, __filenamespace)}
                defaultMessage={preference.replace(/_/g, ' ')}
              />
            }
            control={
              <Checkbox
                checked={value}
                name={preference}
                onChange={(_e, checked) =>
                  CustomerActions.changeEmailPreference({ preference, checked })
                }
              />
            }
          />
        ))}
      </FormGroup>
    </Preference>
  );
}

function PreferencesTab({ customerStore, intl }) {
  return (
    <FlexBox style={styles.root}>
      <div style={styles.column}>
        <DisableClientSchedulingToggle
          preferences={customerStore.customer.preferences}
          onToggle={onChange}
          intl={intl}
        />
        <DisableRSVPToggle
          preferences={customerStore.customer.preferences}
          onToggle={onChange}
          intl={intl}
        />
        <ClientPaymentMethodCheckboxes
          preferences={customerStore.customer.preferences}
          errors={customerStore.fieldErrors}
          onEnableOrDisable={onChange}
          intl={intl}
        />
        <StateChangingButton
          disabled={!customerStore.isValid}
          inProgress={customerStore.isSaving}
          onClick={() => {
            CustomerActions.update({
              id: customerStore.customer.get('id'),
              fields: ['legal_entity', 'logo'],
            });
          }}
        />
      </div>
      <div
        style={{
          marginLeft: LEFT_MARGIN_PIXELS,
          ...styles.column,
        }}
      >
        <ClientEmailSettings
          emailPreferences={customerStore.customer.emailPreferences()}
          intl={intl}
        />
      </div>
    </FlexBox>
  );
}

export default injectIntl(PreferencesTab);
