/* eslint-disable import/no-cycle */
import * as React from 'react';
import PropTypes from 'prop-types';

import { currentCustomer } from 'shared/utils/CustomerUtils.js';

import PaymentActions from '../../Actions';
// eslint-disable-next-line import/no-cycle
import PaysafeCardForm from './PaysafeCardForm.jsx';
import StripeCardForm from './StripeCardForm.jsx';
import { PaymentCard } from '../../records';

import { BILLING_ADDRESS_FIELDS } from '../../records/CardBilling';

function NewCardForm({
  cardRecord = {},
  isEditing = false,
  onChange = undefined,
  style = {},
}) {
  const handleChange = (e, value, name) => {
    let fieldName = e.target.name || name;

    if (BILLING_ADDRESS_FIELDS.includes(fieldName)) {
      fieldName = ['billingAddress', fieldName];
    }

    if (onChange) {
      onChange(fieldName, value);
    } else {
      PaymentActions.updateNewCardForm(fieldName, value);
    }
  };

  if (currentCustomer().use_paysafe) {
    return (
      <PaysafeCardForm
        card={cardRecord}
        isEditing={isEditing}
        onChange={handleChange}
        style={style}
      />
    );
  }

  if (currentCustomer().use_stripe) {
    return (
      <StripeCardForm card={cardRecord} onChange={handleChange} style={style} />
    );
  }

  throw new Error('Unknown gateway');
}

NewCardForm.propTypes = {
  cardRecord: PropTypes.instanceOf(PaymentCard),
  onChange: PropTypes.func,
  isEditing: PropTypes.bool,
  style: PropTypes.object,
};

export default NewCardForm;
