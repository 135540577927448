import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Typography, Button } from '@upperhand/playmaker';

import { t } from 'shared/utils/LocaleUtils';

import Registration from 'shared/records/Registration';

function AttendeeResponse({ registration, onRsvpChanged }) {
  const intl = useIntl();

  if (!registration) return null;

  const rsvpStatus = registration.get('rsvp_status');

  return (
    <div className="attendee-response">
      <div className="attendee-response__title">
        <Typography variant="body1" className="attendee-response__message">
          {t('.attending', intl, __filenamespace)}
        </Typography>
      </div>
      <div className="attendee-response__actions">
        <Button
          rounded
          type="secondary"
          disabled={rsvpStatus === 'attending'}
          classes={{ root: 'attendee-response__actions-yes' }}
          onClick={() => onRsvpChanged(registration, 'attending')}
        >
          {t('.yes', intl, __filenamespace)}
        </Button>
        <Button
          rounded
          type="secondary"
          disabled={rsvpStatus === 'not_attending'}
          classes={{ root: 'attendee-response__actions-no' }}
          onClick={() => onRsvpChanged(registration, 'not_attending')}
        >
          {t('.no', intl, __filenamespace)}
        </Button>
      </div>
    </div>
  );
}

AttendeeResponse.propTypes = {
  registration: PropTypes.instanceOf(Registration).isRequired,
  onRsvpChanged: PropTypes.func.isRequired,
};

export default memo(AttendeeResponse);
