import React, { useState } from 'react';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import { List, Map, Set } from 'immutable';
import { injectIntl } from 'react-intl';

import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import CircularProgress from '@mui/material/CircularProgress';
import { CalendarPicker } from '@mui/x-date-pickers/CalendarPicker';
import { PickersDay } from '@mui/x-date-pickers';

import AvatarWithName from 'shared/components/AvatarWithName.jsx';
import ManageProfilesDialog from 'user_management/shared/components/_ManageProfilesDialog.jsx';
import OpenBookingSchedulerNoScheduling from 'shared/components/OpenBookingSchedulerNoScheduling.jsx';

import {
  formatClientTime,
  customerTZ,
} from 'event_mgmt/shared/utils/DateAndTimeUtils.jsx';
import { currentCustomer } from 'shared/utils/CustomerUtils';
import { currentUser } from 'shared/utils/UserUtils.jsx';
import { smallScreen } from 'shared/utils/DOMUtils';
import { t } from 'shared/utils/LocaleUtils';

import Client from 'shared/records/Client.jsx';
import RegistrationPackage from 'shared/records/RegistrationPackage';
import TentativeRegistration from 'shared/records/TentativeRegistration';
import CustomerEvent from 'event_mgmt/shared/records/CustomerEvent.jsx';
import PerSessionPricingDescription from 'shared/records/automations/PerSessionPricingDescription.jsx';

import uhTheme from '_uh_theme.jsx';

import ManagedClientActions from 'shared/actions/ManagedClientActions.jsx';

const styles = {
  select: {
    '& > div': { height: 'initial' },
    '.UH-MuiSelect-select': {
      padding: '5px',
    },
  },
  selectPlaceholder: {
    display: 'flex',
    alignItems: 'center',
    minHeight: '43px',
    color: 'var(--color-ui-gray)',
    paddingLeft: '5px',
  },
  selectCurrent: {
    svg: {
      display: 'none',
    },
    '.UH-MuiSelect-select': {
      padding: '5px 10px !important',
    },
  },
  selectItem: {
    height: 43,
  },
  selectMultipleItem: {
    display: 'flex',
    alignItems: 'center',
    height: '43px',
  },
  calendarBox: {
    '.UH-MuiPickersCalendarHeader-root': {
      marginTop: '10px',
    },
    '.UH-MuiPickersDay-root:not([class*="Mui-disabled"])': {
      border: '1px solid',
      borderColor: uhTheme.palette.primary.main,
    },
    '.UH-MuiPickersDay-today.Mui-disabled': {
      border: '0 !important',
    },
    '.UH-MuiCalendarPicker-root': {
      width: '100%',
      maxWidth: '320px',
    },
  },
  timeBox: small => ({
    display: 'grid',
    gridTemplateColumns: small ? '1fr 1fr 1fr' : '1fr 1fr 1fr 1fr',
    gridGap: '5px',
    paddingTop: '15px',
    paddingBottom: '40px',
  }),
  timeItem: isSelected => ({
    width: '100%',
    border: '1px solid',
    borderColor: uhTheme.palette.dark.main,
    backgroundColor: isSelected
      ? `${uhTheme.palette.dark.main}!important`
      : uhTheme.palette.dark.contrastText,
    color: isSelected
      ? uhTheme.palette.dark.contrastText
      : `${uhTheme.palette.dark.main}!important`,
  }),
  creditsText: {
    fontWeight: 600,
    margin: '10px 0',
  },
  infoText: {
    marginBottom: '10px',
  },
  actionBtn: {
    height: '50px',
    textTransform: 'initial',
    minWidth: '200px',
    flex: 1,
  },
  dateInfo: {
    textAlign: 'center',
    paddingTop: '15px',
    paddingBottom: '40px',
  },
  schedulingActions: {
    display: 'flex',
    gap: '10px',
    flexWrap: 'wrap',
  },
};

export const compareFn = (a, b) => {
  if (Number(a.get('quantity')) < Number(b.get('quantity'))) {
    return -1;
  }
  if (Number(a.get('quantity')) > Number(b.get('quantity'))) {
    return 1;
  }
  return 0;
};

function RescheduleCurrentSession({ intl, session }) {
  if (!session) return null;

  return (
    <FormControl fullWidth sx={{ marginBottom: '15px' }}>
      <InputLabel shrink>
        {t('.reschedule_current_session', intl, __filenamespace)}
      </InputLabel>
      <Select
        disabled
        notched
        displayEmpty
        label={t('.reschedule_current_session', intl, __filenamespace)}
        value=""
        sx={{
          ...styles.select,
          ...styles.selectCurrent,
        }}
        MenuProps={{ sx: { zIndex: 1301 } }}
        onChange={() => null}
      >
        <MenuItem value="">
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={styles.selectItem}
          >
            <p>{moment(session.starts_at).format('ddd MMMM D, YYYY')}</p>
            <p>{moment(session.starts_at).format('hh:mm A')}</p>
          </Stack>
        </MenuItem>
      </Select>
    </FormControl>
  );
}

function OpenBookingScheduler({
  intl = {},
  isLoading = false,
  availabilitiesLoading = false,
  clientId = null,
  event = new CustomerEvent(),
  eventDiscounts = new Set(),
  allProfiles = List(),
  staff = List(),
  availableTimes = List(),
  filteredAvailableTimes = Map(),
  selectedStaffId = null,
  selectedDate = null,
  selectedDateTimes = Map(),
  clientCredits = Map(),
  packagePricing = null,
  rescheduleRegistrationId = null,
  rescheduleSession = null,
  onStaffChange = () => null,
  onClientChange = () => null,
  onDateChange = () => null,
  onMonthChange = () => null,
  onTimeChange = () => null,
  onAddToCart = () => null,
  onRegistrationBooked = () => null,
  onRegistrationReschedule = () => null,
  onProfileCreated = () => null,
  onClose = () => null,
}) {
  const [openDialog, setOpenDialog] = useState(false);
  const [skipScheduling, setSkipScheduling] = useState(false);
  const eventCredits = clientCredits?.get(event.id);
  const clientCredit = eventCredits?.get(clientId);
  const creditsRemaining = clientCredit?.get('unlimited')
    ? Infinity
    : clientCredit?.get('credits_remaining') || 0;
  const templates =
    packagePricing?.get('template_parameters', List()).sort(compareFn) ||
    List();
  const sessionsCount = selectedDateTimes.reduce(
    (acc, times) => acc + times.size,
    0
  );
  const pricingTemplate = templates.some(
    template =>
      Number(template.get('quantity')) === sessionsCount - creditsRemaining
  );
  const disableIfPricingEnabled = !templates.some(
    template => Number(template.get('quantity')) === sessionsCount
  );
  const disableClientScheduling = currentCustomer().disable_client_scheduling;
  const showAddToCartBtn = creditsRemaining === 0 || disableClientScheduling;
  const showBookBtn = creditsRemaining > 0 && !disableClientScheduling;
  const showCreditsWarning =
    sessionsCount > creditsRemaining &&
    showBookBtn &&
    !rescheduleRegistrationId;
  const showAddPackagePricingWarning =
    packagePricing && selectedDate && !showBookBtn && !rescheduleRegistrationId;
  const disableIfPricingEnabledAndBooking =
    showCreditsWarning && !pricingTemplate;
  const showBookCreditsWarning =
    packagePricing && showBookBtn && sessionsCount > creditsRemaining;
  const hasSelectedTimes = selectedDateTimes.some(times => times.size > 0);
  const allowStaffSelection =
    event.allow_staff_selection &&
    event.getIn(['schedules', 0]).get('customer_user_ids').size;
  const disableBookButton =
    isLoading ||
    !clientId ||
    !hasSelectedTimes ||
    (packagePricing && disableIfPricingEnabledAndBooking);
  const disableAddToCartButton = packagePricing
    ? disableIfPricingEnabled || isLoading || !clientId
    : !hasSelectedTimes || isLoading || !clientId;
  const profileSelectTitle = rescheduleRegistrationId
    ? '.reschedule_profile'
    : '.choose_profile';
  const staffSelectTitle = rescheduleRegistrationId
    ? '.reschedule_choose_staff'
    : '.choose_staff';

  const getTimeItemKey = date => {
    const prefix = rescheduleRegistrationId ? '' : 'any';

    return `${selectedStaffId || prefix}_${clientId}_${date || selectedDate}`;
  };

  const getTentativeDetailsNew = isBooking => {
    const filteredTimes = selectedDateTimes.filter(times => times.size !== 0);
    const tentativeDetailsData = filteredTimes.reduce(
      (accumulator, times, index) => {
        const tentativeRegistration = new TentativeRegistration();
        const [timeStaff, timeClient, date] = index.split('_');
        const details = times.map(time => {
          const newTime = moment(time, 'HH:mm');
          const startsAt = moment(date)
            .clone()
            .hours(newTime.hours())
            .minutes(newTime.minutes())
            .tz(customerTZ(), true);
          const endsAt = startsAt
            .clone()
            .add(event.getIn(['schedules', 0]).duration, 'seconds')
            .tz(customerTZ(), true);

          if (isBooking) {
            return {
              starts_at: startsAt,
              ends_at: endsAt,
              client_id: timeClient,
              staff_ids: timeStaff !== 'any' ? [timeStaff] : [],
              resource_ids: [],
              schedule_id: event.getIn(['schedules', 0]).id,
            };
          }

          const updatedTentativeRegistration = tentativeRegistration
            .set('ends_at', endsAt)
            .set('starts_at', startsAt)
            .set('client_id', timeClient)
            .set('schedule_id', event.getIn(['schedules', 0]).id)
            .set('staff_ids', timeStaff !== 'any' ? [timeStaff] : null);

          return updatedTentativeRegistration;
        });
        return accumulator.concat(details);
      },
      List()
    );
    return tentativeDetailsData;
  };

  const getSelectedStaff = () => {
    if (rescheduleSession?.staff_ids.size > 1) {
      return 'multiple';
    }

    return selectedStaffId || rescheduleSession?.staff_ids.first() || '';
  };

  const handleAddToCart = () => {
    const tentativeDetails = getTentativeDetailsNew();

    let registrationPackage = new RegistrationPackage({
      quantity: tentativeDetails.size,
      event_id: event.id,
      client_ids: [clientId],
    }).set('tentative_details', tentativeDetails);

    if (packagePricing) {
      const template = templates.find(
        tmp => Number(tmp.get('quantity')) === tentativeDetails.size
      );

      registrationPackage = registrationPackage.set(
        'automation_option_uuid',
        template.get('uuid')
      );
      registrationPackage = registrationPackage.set(
        'automation_template_description_id',
        packagePricing.get('id')
      );
    }

    onAddToCart(registrationPackage);
  };

  const handleBooking = () => {
    const tentativeRegistrations = getTentativeDetailsNew(true);

    if (tentativeRegistrations.size <= creditsRemaining) {
      onRegistrationBooked({
        tentativeRegistration: tentativeRegistrations.toJS(),
        repeatBooking: false,
      });
    } else {
      const tentativeToBook = tentativeRegistrations.slice(0, creditsRemaining);
      const tentativeToCart = tentativeRegistrations.slice(
        creditsRemaining,
        tentativeRegistrations.size
      );

      let registrationPackage = new RegistrationPackage({
        quantity: tentativeToCart.size,
        event_id: event.id,
        client_ids: [clientId],
      }).set('tentative_details', tentativeToCart.toJS());

      if (packagePricing) {
        const template = templates.find(
          tmp => Number(tmp.get('quantity')) === tentativeToCart.size
        );

        registrationPackage = registrationPackage.set(
          'automation_option_uuid',
          template.get('uuid')
        );
        registrationPackage = registrationPackage.set(
          'automation_template_description_id',
          packagePricing.get('id')
        );
      }

      onAddToCart(registrationPackage, false);
      onRegistrationBooked({
        tentativeRegistration: tentativeToBook.toJS(),
        repeatBooking: false,
      });
    }
  };

  const handleReschedule = () => {
    const time = selectedDateTimes.first().first();
    const newTime = moment(time, 'HH:mm');
    const startsAt = moment(selectedDate)
      .clone()
      .hours(newTime.hours())
      .minutes(newTime.minutes())
      .tz(customerTZ(), true);
    const endsAt = startsAt
      .clone()
      .add(event.getIn(['schedules', 0]).duration, 'seconds')
      .tz(customerTZ(), true);

    onRegistrationReschedule({
      params: { starts_at: startsAt, ends_at: endsAt },
    });
  };

  const shouldDisableDate = date =>
    filteredAvailableTimes
      .get(moment(date).format('YYYY-MM-DD'), List())
      .isEmpty();

  const handleOpenDialog = () => {
    ManagedClientActions.updateStore(
      new Client({ managed_by_id: currentUser().id }).toJS()
    );
    setOpenDialog(true);
  };

  const handleAfterProfileCreate = ({ id }) => {
    onProfileCreated(id);
    setOpenDialog(false);
  };

  const handleClientChange = (e, _, id) => {
    if (id) {
      onClientChange(id);
      return;
    }

    const { value } = e.target;

    if (value !== 'create-new') {
      onClientChange(value);
    } else {
      handleOpenDialog();
    }
  };

  const renderProfileSelectValue = val => {
    const selectedProfile = allProfiles.find(p => p.id === val);

    if (selectedProfile) {
      return (
        <AvatarWithName user={selectedProfile} style={styles.selectItem} />
      );
    }

    return (
      <Typography sx={styles.selectPlaceholder}>
        {t('.select_profile', intl, __filenamespace)}
      </Typography>
    );
  };

  if (skipScheduling) {
    return (
      <OpenBookingSchedulerNoScheduling
        clientId={clientId}
        event={event}
        profiles={allProfiles}
        packagePricing={packagePricing}
        eventDiscounts={eventDiscounts}
        onClientChange={handleClientChange}
        onReturnToScheduling={() => {
          setSkipScheduling(false);
        }}
        onAddToCart={onAddToCart}
        onProfileCreated={onProfileCreated}
      />
    );
  }

  return (
    <>
      <Box>
        {!rescheduleRegistrationId && (
          <Typography variant="subtitle1" sx={styles.infoText}>
            {t('.info', intl, __filenamespace)}
          </Typography>
        )}

        {rescheduleRegistrationId && (
          <RescheduleCurrentSession intl={intl} session={rescheduleSession} />
        )}

        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          spacing={1}
        >
          {allowStaffSelection && (
            <Grid item xs={12} sm={6} padding={1}>
              <FormControl fullWidth>
                <InputLabel shrink>
                  {t(staffSelectTitle, intl, __filenamespace)}
                </InputLabel>
                <Select
                  disabled={isLoading || Boolean(rescheduleRegistrationId)}
                  notched
                  displayEmpty
                  label={t(staffSelectTitle, intl, __filenamespace)}
                  value={getSelectedStaff()}
                  sx={{
                    ...styles.select,
                    ...(rescheduleRegistrationId ? styles.selectCurrent : {}),
                  }}
                  MenuProps={{ sx: { zIndex: 1301 } }}
                  onChange={e => onStaffChange(e.target.value)}
                >
                  <MenuItem value="">
                    <AvatarWithName
                      name={t('.any_staff', intl, __filenamespace)}
                      letter="S"
                      style={styles.selectItem}
                    />
                  </MenuItem>
                  {staff.map(s => (
                    <MenuItem key={s.id} value={s.id}>
                      <AvatarWithName user={s} style={styles.selectItem} />
                    </MenuItem>
                  ))}
                  {rescheduleSession &&
                    rescheduleSession.staff_ids.size > 1 && (
                      <MenuItem value="multiple">
                        <Typography sx={styles.selectMultipleItem}>
                          {t(
                            '.reschedule_multiple_staff',
                            intl,
                            __filenamespace
                          )}
                        </Typography>
                      </MenuItem>
                    )}
                </Select>
              </FormControl>
            </Grid>
          )}

          <Grid
            item
            xs={12}
            sm={allowStaffSelection ? 6 : 12}
            padding={{ xs: 1, sm: allowStaffSelection ? 0 : 1 }}
          >
            <FormControl fullWidth>
              <InputLabel shrink>
                {t(profileSelectTitle, intl, __filenamespace)}
              </InputLabel>
              <Select
                notched
                displayEmpty
                disabled={isLoading || Boolean(rescheduleRegistrationId)}
                label={t(profileSelectTitle, intl, __filenamespace)}
                value={clientId || ''}
                renderValue={renderProfileSelectValue}
                sx={{
                  ...styles.select,
                  ...(rescheduleRegistrationId ? styles.selectCurrent : {}),
                }}
                MenuProps={{ sx: { zIndex: 1301 } }}
                onChange={handleClientChange}
              >
                <MenuItem key="create-new" value="create-new">
                  <Typography>
                    {t('.new_profile', intl, __filenamespace)}
                  </Typography>
                </MenuItem>
                {allProfiles.map(c => (
                  <MenuItem key={c.id} value={c.id}>
                    <AvatarWithName user={c} style={styles.selectItem} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        {showBookBtn && !rescheduleRegistrationId ? (
          <Typography variant="subtitle2" sx={styles.creditsText}>
            {t('.available_credits', intl, __filenamespace, {
              creditsRemaining,
            })}
          </Typography>
        ) : null}

        <Box sx={styles.calendarBox}>
          <CalendarPicker
            disablePast
            loading={availabilitiesLoading}
            date={selectedDate ? moment(selectedDate) : moment()}
            disabled={isLoading || availabilitiesLoading}
            shouldDisableDate={shouldDisableDate}
            onChange={onDateChange}
            onMonthChange={onMonthChange}
            renderLoading={() => <CircularProgress />}
            renderDay={(day, selectedDays, pickersDayProps) => (
              <PickersDay
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...pickersDayProps}
                selected={
                  !!selectedDateTimes.get(
                    getTimeItemKey(moment(day).format('YYYY-MM-DD'))
                  )
                }
              />
            )}
          />
        </Box>

        <Divider />

        {!selectedDate && (
          <Typography sx={styles.dateInfo}>
            {t('.date_warning', intl, __filenamespace)}
          </Typography>
        )}

        {(showAddPackagePricingWarning || showBookCreditsWarning) && (
          <Typography sx={{ paddingTop: '15px' }}>
            {t('.package_pricing_warning', intl, __filenamespace)}
            &nbsp;
            {templates.map((template, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <span key={index} style={{ fontWeight: 'bold' }}>
                {templates.size > 2 && index === templates.size - 1
                  ? t('.pkg_pre_or', intl, __filenamespace)
                  : null}
                {t('.package_pricing_warning_sessions', intl, __filenamespace, {
                  sessionsCount: template.get('quantity'),
                })}
                {templates.size === 2 && index === 0
                  ? t('.pkg_after_or', intl, __filenamespace)
                  : null}
                {templates.size > 2 && index < templates.size - 1 ? ',' : null}
                &nbsp;
              </span>
            ))}
          </Typography>
        )}

        {((showCreditsWarning && !packagePricing) ||
          (showCreditsWarning && packagePricing && pricingTemplate)) && (
          <Typography sx={{ paddingTop: '15px' }}>
            {t('.credits_warning', intl, __filenamespace, {
              creditsRemaining,
              sessionsCount,
              sessionBooked: sessionsCount - creditsRemaining,
            })}
          </Typography>
        )}

        {showCreditsWarning && packagePricing && !pricingTemplate && (
          <Typography sx={{ paddingTop: '15px' }}>
            {t('.credits_warning_pkg_pricing', intl, __filenamespace, {
              creditsRemaining,
              sessionsCount,
              sessionBooked: creditsRemaining,
            })}
          </Typography>
        )}

        {selectedDate && (
          <Box sx={styles.timeBox(smallScreen())}>
            {availableTimes.map(at => (
              <MenuItem
                disabled={isLoading}
                key={at}
                value={at}
                sx={styles.timeItem(
                  selectedDateTimes?.get(getTimeItemKey())?.get(at)
                )}
                onClick={() => onTimeChange(at)}
              >
                {formatClientTime(at, 'h:mm A')}
              </MenuItem>
            ))}
          </Box>
        )}
      </Box>

      {!rescheduleRegistrationId && (
        <>
          <Box sx={styles.schedulingActions}>
            <Button
              fullWidth
              variant="contained"
              color="dark"
              sx={styles.actionBtn}
              onClick={() => {
                setSkipScheduling(true);
              }}
            >
              {t('.skip_scheduling', intl, __filenamespace)}
            </Button>
            {showAddToCartBtn && (
              <Button
                fullWidth
                variant="contained"
                disabled={disableAddToCartButton}
                sx={styles.actionBtn}
                onClick={handleAddToCart}
              >
                {!isLoading
                  ? t('.add_to_cart', intl, __filenamespace, {
                      sessionsCount: sessionsCount || '',
                    })
                  : t('.adding_to_cart', intl, __filenamespace)}
              </Button>
            )}
            {showBookBtn && (
              <Button
                fullWidth
                color="dark"
                variant="contained"
                disabled={disableBookButton}
                sx={styles.actionBtn}
                onClick={handleBooking}
              >
                {!isLoading
                  ? t('.book', intl, __filenamespace, {
                      sessionsCount: sessionsCount || '',
                    })
                  : t('.booking', intl, __filenamespace)}
              </Button>
            )}
          </Box>
          <ManageProfilesDialog
            open={openDialog}
            closeDialog={() => setOpenDialog(false)}
            afterCreate={handleAfterProfileCreate}
          />
        </>
      )}

      {rescheduleRegistrationId && (
        <Stack direction="row" spacing={2} justifyContent="space-between">
          <Button
            fullWidth
            disabled={isLoading}
            color="dark"
            variant="outlined"
            sx={{ height: '50px' }}
            onClick={onClose}
          >
            {t('.reschedule_cancel', intl, __filenamespace)}
          </Button>
          <Button
            fullWidth
            color="dark"
            variant="contained"
            sx={{ height: '50px' }}
            disabled={isLoading}
            onClick={handleReschedule}
          >
            {t(
              isLoading ? '.reschedule_confirmed' : '.reschedule_confirm',
              intl,
              __filenamespace
            )}
          </Button>
        </Stack>
      )}
    </>
  );
}

OpenBookingScheduler.propTypes = {
  intl: PropTypes.object,
  isLoading: PropTypes.bool,
  availabilitiesLoading: PropTypes.bool,
  clientId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  event: PropTypes.instanceOf(CustomerEvent),
  eventDiscounts: PropTypes.instanceOf(Set),
  allProfiles: PropTypes.instanceOf(List),
  staff: PropTypes.instanceOf(List),
  availableTimes: PropTypes.instanceOf(List),
  filteredAvailableTimes: PropTypes.instanceOf(Map),
  selectedStaffId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  selectedDate: PropTypes.string,
  selectedDateTimes: PropTypes.instanceOf(Map),
  clientCredits: PropTypes.instanceOf(Map),
  packagePricing: PropTypes.instanceOf(PerSessionPricingDescription),
  rescheduleRegistrationId: PropTypes.string,
  rescheduleSession: PropTypes.object,
  onStaffChange: PropTypes.func,
  onClientChange: PropTypes.func,
  onDateChange: PropTypes.func,
  onMonthChange: PropTypes.func,
  onTimeChange: PropTypes.func,
  onAddToCart: PropTypes.func,
  onRegistrationBooked: PropTypes.func,
  onRegistrationReschedule: PropTypes.func,
  onProfileCreated: PropTypes.func,
  onClose: PropTypes.func,
};

export default injectIntl(OpenBookingScheduler);
