import React, { memo } from 'react';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Grid, Typography } from '@upperhand/playmaker';

import FormattedCurrency from 'shared/components/FormattedCurrency.jsx';
import ClientDetails from 'shared/components/client/clientDetails/ClientDetails.jsx';

import altContainer from 'shared/hocs/altContainer.jsx';
import { compose } from 'shared/utils/SharedUtils';
import { t } from 'shared/utils/LocaleUtils';

import { ClientDataStore } from 'dataStores';
import AccountCreditStore, {
  CREDIT_TYPES,
} from 'containers/clientProfile/components/AccountCredits/Store';

import { CreditItem } from './ListStep.jsx';

function DetailsStep({
  intl = {},
  clientDataStore = {},
  accountCreditStore = {},
}) {
  const { clientId, credit } = accountCreditStore;
  const { clients } = clientDataStore;
  const client = clients.get(clientId, null);

  const note = credit.get('note');
  const order = credit.get('order');
  const createdBy = credit.get('created_by');
  const creditType = credit.get('credit_type');

  return (
    <div className="account-credits-details">
      <Grid container direction="column" alignItems="center" justify="center">
        <Grid item>
          <Typography
            variant="body1"
            className="account-credits-details__title"
          >
            {t(`.${credit.get('credit_type')}`, intl, __filenamespace)}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="h3" className="account-credits-details__amount">
            <FormattedCurrency fromCents value={credit.get('amount')} />
          </Typography>
        </Grid>
      </Grid>
      {client && <ClientDetails client={client} />}
      <Grid container className="account-credits-details__credit-info">
        <Grid item>
          <Typography
            variant="caption"
            className="account-credits-details__credit-info-title"
          >
            {t('.credit_info', intl, __filenamespace)}
          </Typography>
        </Grid>
        {creditType !== CREDIT_TYPES.apply && (
          <Grid item container justify="space-between">
            <Grid item xs={4}>
              <Typography variant="caption">
                {creditType === CREDIT_TYPES.clear
                  ? t('.cleared_by', intl, __filenamespace)
                  : t('.added_by', intl, __filenamespace)}
              </Typography>
            </Grid>
            <Grid item container xs={8} justify="flex-end">
              <Typography variant="caption">{createdBy.name()}</Typography>
            </Grid>
          </Grid>
        )}
        {creditType === CREDIT_TYPES.apply && order && (
          <>
            <Grid item container justify="space-between">
              <Grid item xs={4}>
                <Typography variant="caption">
                  {t('.date_applied', intl, __filenamespace)}
                </Typography>
              </Grid>
              <Grid item container xs={8} justify="flex-end">
                <Typography variant="caption">
                  {moment(credit.get('created_at')).format('MM/DD/YYYY')}
                  &nbsp;&bull;&nbsp;
                  {moment(credit.get('created_at')).format('h:mma')}
                </Typography>
              </Grid>
            </Grid>
            <Grid item container justify="space-between">
              <Grid item xs={4}>
                <Typography variant="caption">
                  {t('.order_method', intl, __filenamespace)}
                </Typography>
              </Grid>
              <Grid item container xs={8} justify="flex-end">
                <Typography variant="caption">
                  {order.get('sale_source')
                    ? t(
                        `.order_method_${order.get('sale_source')}`,
                        intl,
                        __filenamespace
                      )
                    : 'N/A'}
                </Typography>
              </Grid>
            </Grid>
            <Grid item container justify="space-between">
              <Grid item xs={4}>
                <Typography variant="caption">Order Number:</Typography>
              </Grid>
              <Grid item container xs={8} justify="flex-end">
                <Typography variant="caption">
                  {order.get('order_number') || 'N/A'}
                </Typography>
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
      {note && (
        <Grid container className="account-credits-details__credit-notes">
          <Grid item container justify="space-between">
            <Grid item xs={4}>
              <Typography variant="caption">
                {t('.notes', intl, __filenamespace)}
              </Typography>
            </Grid>
            <Grid item container xs={8} justify="flex-end">
              <Typography
                variant="caption"
                className="account-credits-details__note-content"
              >
                {note || 'N/A'}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      )}
      <Typography
        variant="caption"
        className="account-credits-details__credit-details"
      >
        {t('.details', intl, __filenamespace)}
      </Typography>
      <CreditItem
        className="account-credits-details"
        isClickable={false}
        credit={credit}
      />
    </div>
  );
}

DetailsStep.propTypes = {
  intl: PropTypes.object,
  clientDataStore: PropTypes.object,
  accountCreditStore: PropTypes.object,
};

export default compose(
  memo,
  injectIntl,
  altContainer({
    stores: {
      clientDataStore: ClientDataStore,
      accountCreditStore: AccountCreditStore,
    },
  })
)(DetailsStep);
