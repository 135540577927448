import React, { memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Map } from 'immutable';
import { Tabs, Typography } from '@upperhand/playmaker';

import AttendeeList from 'containers/events/admin/sessionSummaryDrawer/components/rsvp/components/AttendeeList.jsx';
import AttendeeResponse from 'containers/events/admin/sessionSummaryDrawer/components/rsvp/components/AttendeeResponse.jsx';

import altContainer from 'shared/hocs/altContainer.jsx';
import { compose } from 'shared/utils/SharedUtils';
import { currentUser } from 'shared/utils/UserUtils.jsx';
import { t } from 'shared/utils/LocaleUtils';

import { SessionDataStore, RegistrationDataStore } from 'dataStores';

import RSVPActions from './actions';
import RSVPStore from './store';

import './styles.scss';

const tabs = ({ isLoading, session, registrations, intl }) => {
  const rsvp = session.get('rsvp_status_map');
  const sessionId = session.get('id');
  const attending = rsvp.get('attending');
  const pending = rsvp.get('pending');
  const notAttending = rsvp.get('not_attending');

  const attendingRegistrations = registrations.filter(
    r => attending.includes(r.get('client_id')) && r.session_id === sessionId
  );
  const pendingRegistrations = registrations.filter(
    r => pending.includes(r.get('client_id')) && r.session_id === sessionId
  );
  const notAttendingRegistrations = registrations.filter(
    r => notAttending.includes(r.get('client_id')) && r.session_id === sessionId
  );

  return [
    {
      label: (
        <Typography variant="subtitle2">
          {t('.attending', intl, __filenamespace, {
            count: attending.size,
          })}
        </Typography>
      ),
      content: (
        <AttendeeList
          key="attending"
          isLoading={isLoading}
          registrations={attendingRegistrations}
        />
      ),
      value: 'attending',
      key: 'attending',
    },
    {
      label: (
        <Typography variant="subtitle2">
          {t('.pending', intl, __filenamespace, { count: pending.size })}
        </Typography>
      ),
      content: (
        <AttendeeList
          showRemindBtn
          key="pending"
          isLoading={isLoading}
          registrations={pendingRegistrations}
          onRemindClick={r => RSVPActions.remind(r.id)}
        />
      ),
      value: 'pending',
      key: 'pending',
    },
    {
      label: (
        <Typography variant="subtitle2">
          {t('.not_attending', intl, __filenamespace, {
            count: notAttending.size,
          })}
        </Typography>
      ),
      content: (
        <AttendeeList
          key="not-attending"
          isLoading={isLoading}
          registrations={notAttendingRegistrations}
        />
      ),
      value: 'not-attending',
      key: 'not-attending',
    },
  ];
};

function RSVP({
  sessionId,
  rsvpStore,
  registrationDataStore,
  sessionDataStore,
}) {
  const intl = useIntl();

  useEffect(() => {
    if (sessionId) {
      RSVPActions.mounted({ sessionId });
    }
  }, [sessionId]);

  const { registrations } = registrationDataStore;
  const { sessions } = sessionDataStore;
  const { selectedTab, registrationId, loadingClients, loadingRegistrations } =
    rsvpStore;

  const session = sessions.get(sessionId);
  const registration = registrations.get(registrationId);
  const isClient = currentUser().isClient();
  const isLoading = loadingClients || loadingRegistrations;

  const handleRsvpChanged = (reg, status) => {
    RSVPActions.updateRsvpStatus({
      registrationId: reg.get('id'),
      status,
    });
  };

  return (
    <div className="rsvp">
      {!isClient && (
        <Tabs
          classes={{
            root: 'rsvp-tabs',
          }}
          tabItems={tabs({
            isLoading,
            session,
            registrations,
            intl,
          })}
          value={selectedTab}
          onChange={(_, value) => RSVPActions.selectTab(value)}
        />
      )}
      {isClient && (
        <AttendeeResponse
          registration={registration}
          onRsvpChanged={handleRsvpChanged}
        />
      )}
    </div>
  );
}

RSVP.propTypes = {
  sessionId: PropTypes.string.isRequired,
  sessionDataStore: PropTypes.shape({
    sessions: PropTypes.instanceOf(Map).isRequired,
  }).isRequired,
};

export default compose(
  memo,
  altContainer({
    stores: {
      sessionDataStore: SessionDataStore,
      registrationDataStore: RegistrationDataStore,
      rsvpStore: RSVPStore,
    },
  })
)(RSVP);
